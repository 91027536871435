
#name {
    background: transparent;
    border: none;
    border-bottom: 1px solid #000000;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    width: 30%;

}

.form .statement-sus {
    display:block;
    font-size: 1.4em;
    font-weight: bold;
    padding: 30px 0 0 4.25%;
    margin-bottom:10px;
  }

.form .likert-sus {
    list-style:none;
    width:100%;
    margin:0;
    padding:0 0 35px;
    display:block;
    border-bottom:2px solid #efefef;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .form .likert-sus:last-of-type {border-bottom:0;}
  .form .likert-sus:before {
    content: '';
    position:relative;
    top:11px;
    left:18%;
    display:block;
    background-color:grey;
    height:4px;
      
    width:65%;
  }
  .form .likert-sus li {
    display:inline-block;
    width:16%;
    text-align:center;
    vertical-align: top;
    font-size: 1.2em;
  }
  .form .likert-sus li input[type=radio] {
    display:block;
    position:relative;
    top:-4px;
    left:50%;
    margin-left:-6px;
    
  }
  .form .likert-sus li label {width:100%;}
