.tg {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }
  .tg td {
    border-color: #dee2e6;
    border-style: solid;
    border-width: 1px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    overflow: hidden;
    padding: 10px 5px;
    word-break: normal;
  }
  .tg th {
    border-color: #dee2e6;
    border-style: solid;
    border-width: 1px;
    font-weight: normal;
    overflow: hidden;
    padding: 10px 5px;
    word-break: normal;
  }
  .tg .tg-0lax {
    text-align: left;
    vertical-align: top;
  }
  