body {
    font-family: Montserrat, sans-serif;
    color: #3b3735;
    font-size: 1em;
  }
  
  a {
    color: #00bfa5;
    display:inline-block;
    text-decoration: none;
    font-weight: 400;
  }
  
  [type=radio] { 
      opacity: 1;
    }
   
    
    /* IMAGE STYLES */
    [type=radio] + img {
      cursor: pointer;
      transition: transform .4s;
    }
  
    /* CHECKED STYLES */
   
    
    .wrap 
    h1.likert-header {
      padding-left:4.25%;
      margin:20px 0 0;
    }
    .form .statement {
      display:block;
      font-size: 1.em;
      font-weight: bold;
      padding: 30px 0 0 4.25%;
      margin-bottom:10px;
    }
    .form .likert {
      list-style:none;
      width:100%;
      margin:0;
      padding:0 0 35px;
      display:block;
      margin-top: 3rem;
      margin-bottom: 3rem;
    }
    .form .likert:last-of-type {border-bottom:0;}
    .form .likert:before {
      content: '';
      position:relative;
      top:11px;
      left:10%;
      display:block;
      background-color:grey;
      height:4px;
      width:80%;
    }
    .form .likert li {
      display:inline-block;
      width:10%;
      text-align:center;
      vertical-align: top;
    }
    .form .likert li input[type=radio] {
      display:block;
      position:relative;
      top:-4px;
      left:50%;
      margin-left:-6px;
      
    }
    .form .likert li label {width:100%;}
  
    input[type=radio]{
      transform:scale(1.5);
    }
    .whitebutton{
      background-color: #00bfa5;
      color: black;
      border: 1px #00bfa5 solid;
  }
  
  .whitebutton:hover {
      background-color: grey;
  }
  
  .accordion-button {
    color: #00bfa5;
  }
  
  