.cardBtn:hover{
    cursor: pointer;
    background-color: #00bfa588;
}

.cardBtn{
    border-radius: 5%;
    overflow: hidden;
    box-shadow: 0 20px 50px 0 rgba(0,0,0,0.3);
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
}

.description{
    max-height: 100px;
}

.card-title {
    margin-top: 9rem; 
    margin-left: 7.5rem;
    font-size: 2em;
    
}