.form .statement-zanon {
  display:block;
  font-size: 1.4em;
  font-weight: bold;
  padding: 30px 0 0 4.25%;
  margin-bottom:10px;
}

.form .likert-zanon {
  list-style:none;
  width:100%;
  margin:0;
  padding:0 0 35px;
  display:block;
  border-bottom:2px solid #efefef;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.form .likert-zanon:last-of-type {border-bottom:0;}
.form .likert-zanon:before {
  content: '';
  position:relative;
  top:11px;
  left:18%;
  display:block;
  background-color:grey;
  height:4px;
    
  width:65%;
}
.form .likert-zanon li {
  display:inline-block;
  width:16%;
  text-align:center;
  vertical-align: top;
  font-size: 1.2em;
}
.form .likert-zanon li input[type=radio] {
  display:block;
  position:relative;
  top:-4px;
  left:50%;
  margin-left:-6px;
  
}
.form .likert-zanon li label {width:100%;}


#just-line-break {
  white-space: pre-line;
}

#line-break-and-tab {
  white-space: pre-wrap;
}


.radio{
  position: relative;

}

.radio-form{
  position: relative;
  width: max-content;
}

.radio-form:after{
  display: block;
  content: "";
  position: absolute;
  top: 42%;
  left: 9%;
  background: #000;
  height: 2px;
  width: 78.5%;
}

#root {
  width: 100%;
}