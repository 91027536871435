.masthead {
    min-height: 30rem;
    position: relative;
    display: table;
    width: 100%;
    height: auto;
    padding-top: 8rem;
    padding-bottom: 8rem;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%), url("../LandingPage/bg.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .masthead h1, .masthead .h1 {
    font-size: 4rem;
    margin: 0;
    padding: 0;
  }
  @media (min-width: 992px) {
    .masthead {
      height: 100vh;
    }
    .masthead h1, .masthead .h1 {
      font-size: 5.5rem;
    }
  }

  .btn-xl {
    padding: 10px 20px;
    font-size: 30px;
    border-radius: 10px;

}



  .btn-xl {
    padding: 10px 20px;
    font-size: 30px;
    border-radius: 10px;

}

.about {
    min-height: 30rem;
    position: relative;
    display: table;
    width: 100%;
    height: auto;
    padding-top: 5rem;
    padding-bottom: 2rem;


}

.bgA {

    background-color: #fdc314;

}

.bgB {

    background-color: #1881bf;

}

.bgC {

    background-color: #ef7700;


}

.recsys {
    min-height: 30rem;
    position: relative;
    display: table;
    width: 100%;
    height: auto;
    padding-top: 8rem;
    padding-bottom: 8rem;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%), url("../LandingPage/bg3.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }