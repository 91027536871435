
.form .statement-panas {
    display:block;
    font-size: 1.4em;
    font-weight: bold;
    padding: 30px 0 0 4.25%;
    margin-bottom:10px;
  }

.form .likert-panas {
    list-style:none;
    width:100%;
    margin:0;
    padding:0 0 35px;
    display:block;
    border-bottom:2px solid #efefef;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .form .likert-panas:last-of-type {border-bottom:0;}
  .form .likert-panas:before {
    content: '';
    position:relative;
    top:11px;
    left:17%;
    display:block;
    background-color:grey;
    height:4px;
    width:66%;
  }
  .form .likert-panas li {
    display:inline-block;
    width:16.5%;
    text-align:center;
    vertical-align: top;
    font-size: 1.2em;
  }
  .form .likert-panas li input[type=radio] {
    display:block;
    position:relative;
    top:-4px;
    left:50%;
    margin-left:-6px;
    
  }
  .form .likert-panas li label {width:100%;}