.coremo {
    background-color: #00bfa5;
}

.navbar ul li{
    display:inline-block;
 }

 .margin_top {
    margin-top: 60px; 
 }