@media print {
    @page {
      size: 330mm 427mm;
      margin: 14mm;
    }
    .container {
      width: 1170px;
      justify-content: center;
    }
    
    header h1, header nav, footer, img {
      display: none;
      }
      table, img, svg {
        break-inside: avoid;
      }

      .no-print, .no-print *
      {
          display: none !important;
      }
      body {
        -webkit-print-color-adjust: exact;
        -moz-print-color-adjust: exact;
        -ms-print-color-adjust: exact;
        print-color-adjust: exact;
    }
  }